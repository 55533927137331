<template>
  <div>
		<div class="m-subheader ">
			<div class="d-flex align-items-center">
				<div class="mr-auto">
					<h3 class="m-subheader__title m-subheader__title--separator">Editar Parceiro</h3>
					<ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
						<li class="m-nav__item m-nav__item--home">
							<router-link :to="{name: 'home'}" class="m-nav__link m-nav__link--icon">
								<i class="m-nav__link-icon la la-home"></i>
							</router-link>
						</li>
						<li class="m-nav__separator">-</li>
						<li class="m-nav__item">
							<router-link :to="{name:'partners'}" class="m-nav__link">
								<span class="m-nav__link-text">Provedores</span>
							</router-link>
						</li>
						<li class="m-nav__separator">-</li>
						<li class="m-nav__item">
							<router-link :to="{name:'edit.partner'}" class="m-nav__link">
								<span class="m-nav__link-text">Editar Parceiro</span>
							</router-link>
						</li>
					</ul>
				</div>
        <button class="m-btn btn btn-info" @click="update">
					Alterar
        </button>
			</div>
		</div>
				<u-portlet :title="company.name">
					<div class="row">
						<div class="col-12 text-center" title="Clique para alterar o logo">
							<div id="preview">
								<div id="preview">
									<img v-if="company.logo_url" :src="company.logo_url" ref="logo" @click="() => { $refs.file.click() }">
									<img v-else src="../../../assets/img/logo.png" ref="logo" @click="() => { $refs.file.click() }">
								</div>
								<input type="file" class="sr-only all-files" ref="file" @change="onFileChange">
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-12">
							<div class="form-group">
								<label class="form-control-label">Empresa</label>
							  <input class="form-control" v-model="company.name"/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6">
							<div class="form-group">
						  	<label class="form-control-label">Email</label>
						    <input type="email" v-model="company.contact_email" class="form-control"/>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="form-group">
								<label class="form-control-label">Telefone</label>
								<the-mask class="form-control" v-model="company.contact_phone" :mask="['(##) ####-####', '(##) #####-####']" />
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-6">
							<div class="form-group">
								<label class="form-control-label">Key</label>
								<input class="form-control" ref="company_keyword" maxlength="4" @blur="verifyKeywordExists" @keyup="() => { company.key = company.key.toUpperCase() }" v-model="company.key"/>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="form-group">
								<label class="form-control-label">Perfil</label>
							  <v-select :options="companyProfiles" v-model="companyProfile"></v-select>
							</div>
						</div>
					</div>
					<div class="m-portlet m-portlet--mobile m-portlet--body-progress- col-12">
                    <div class="m-portlet__head">
                      <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                          <h3 class="m-portlet__head-text">
                            Endereço
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="m-portlet__body">
											<div class="row">
												<div class="col-6">
													<div class="form-group m-form__group">
														<label class="form-control-label">Endereço</label>
														<input class="form-control" v-model="company.location_address"/>
													</div>
												</div>
												<div class="col-6">
													<div class="form-group m-form__group">
														<label class="form-control-label">Número</label>
														<input class="form-control" v-model="company.location_number"/>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-6">
													<div class="form-group m-form__group">
														<label class="form-control-label">Complemento</label>
														<input class="form-control" v-model="company.location_additional_info"/>
													</div>
												</div>
												<div class="col-6">
													<div class="form-group m-form__group">
														<label class="form-control-label">Bairro</label>
														<input class="form-control" v-model="company.location_neighborhood"/>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-6">
													<div class="form-group m-form__group">
														<label class="form-control-label">Cidade</label>
														<input class="form-control" v-model="company.location_city"/>
													</div>
												</div>
												<div class="col-6">
													<div class="form-group m-form__group">
														<label class="form-control-label">Estado</label>
														<input class="form-control" v-model="company.location_state"/>
													</div>
												</div>
											</div>
                  	</div>
								</div>
				</u-portlet>

    <div title="Integrações" v-for="(integration, integrationIndex) in company.integrations" :key= "integrationIndex" class="m-portlet m-portlet--mobile m-portlet--body-progress-" id="service-offers">
		<div class="m-portlet__head">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
									Integrações
                </h3>
              </div>
            </div>
			<div class="m-portlet__head-tools">
        <a  href="javascript:;" @click="removeIntegration(integrationIndex)">
          <i class="fas fa-times"></i>
       </a>
      </div>
    </div>
		<div class="m-portlet__body offer-content">
			<div class="form-group row">
				<div class="col-lg-12">
					<label class="form-control-label">Environment</label>
					<v-select :options="['production', 'pre-production']" v-model="company.integrations[integrationIndex].environment" label="providerLabel" value="value"></v-select>
				</div>
				<div class="col-lg-12 mt-2">
					<label class="form-control-label">URL MO</label>
					<input class="form-control" v-model="company.integrations[integrationIndex].url_mo"/>
				</div>
			</div>
      <div class="form-group row">
				<div class="col-lg-12">
					<label class="form-control-label">Datasync</label>
					<input class="form-control" v-model="company.integrations[integrationIndex].datasync" />
				</div>
				<div class="col-lg-12 mt-2">
					<label class="form-control-label">Lista de IPs</label>
					<input-tag :tags.sync="company.integrations[integrationIndex].ip_list" v-model="company.integrations[integrationIndex].ip_list"></input-tag>
				</div>
			</div>
		</div>
		</div>
    <div class="row mb-5">
      <div class="col-12 mt-4">
        <div class="text-center">
          <button type="button" class="btn btn-success text-uppercase" @click="addIntegration">
            <i class="fas fa-plus"></i>
                nova Integração
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UPortlet from '@/components/UPortlet';
import vSelect from 'vue-select';
import PartnerService from '../services/PartnerService';
import InputTag from 'vue-input-tag'

export default {
	data () {
		return{
			formData: new FormData(),
			partnerService: new PartnerService(),
			selected: '',
			company: {
				profile_id: '',
        key: '',
        name: '',
        type: '',
				status: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        location_address: '',
        location_additional_info: '',
        location_neighborhood: '',
        location_number: '',
        location_city: '',
				location_state: '',
				integrations: []
			},
			url: null,
			integration:{
				environment: '',
				url_mo: '',
				datasync: '',
				ip_list: '',
			},
			companyProfiles: [],
			companyProfile: {},
			ip: ["123","098","982"]

    }
	},
  components: {
		UPortlet,
		vSelect,
		PartnerService,
		InputTag,
	},
  created() {
		this.setPartner();
  },
	methods: {
		update () {
			this.setFormData();
			let idPartner = this.$route.params.id;
			
		 	axios.post(`company/company/${idPartner}`, this.formData)
		 	.then(response => {
				 swal('Sucesso!', 'Informações da empresa alteradas com sucesso!', 'success');
				 this.formData = new FormData();
				 })
		 	.catch(err => swal('Ops!', 'Não foi possível efetuar as alterações!', 'error'));
		},

		setFormData () {
			let keys = Object.keys(this.company);
			let integrations = Object.keys(this.integration);
			
			this.formData.append('profile_id', this.companyProfile.value);
			keys.forEach((key) => {
				if(key == "integrations") {
					this.company[key].forEach((integration) => {
					 this.formData.append("integrations[]", JSON.stringify(integration));
					});
				}
				else if(key == "profile_id"){
					this.company.profile_id = this.companyProfile.value;
				}
				else {
					this.formData.append(key,this.company[key] || '');
				}
			})
		},
		addIntegration () {
			this.company.integrations.push(this.integration);
			this.integration = {
				environment: '',
				url_mo: '',
				datasync: '',
				ip_list: '',
			}
		},
		createIntegrations () {
		let	data = {
				environment: this.integration.environment,
				url_mo: this.integration.url_mo,
				datasync: this.integration.datasync,
				ip_list: this.integration.ip_list
			}
		
		this.company.integrations.unshift(data);
		},
		onFileChange(e) {
			const file = e.target.files[0];
			if(file) {
				let reader = new FileReader();
				 reader.onload = (e) => {
              this.$refs.logo.src = e.target.result;
           }

           reader.readAsDataURL(file);
			 }

			 this.formData.append("file", this.$refs.file.files[0]);
		},
		removeIntegration(integrationIndex) {
			this.company.integrations.splice(integrationIndex, 1);
		},
    setPartner () {
      let idPartner = this.$route.params.id;
			return this.partnerService.get(idPartner)
      .then(response => {
				this.company =response.data.company;
				
				this.setCompanyProfles(response.data.company_profiles);
				this.setCompanyProfile(response.data.company.profile);
      })
      .catch(error => console.log(error));
		},
		setCompanyProfles (profiles) {
			if(profiles){
				this.companyProfiles = [];
				profiles.forEach((profile) => {
					this.companyProfiles.push({
						label: profile.name,
						value: profile.id
					})
				})
			}
		},
		setCompanyProfile(profile) {
      this.companyProfile = {
				label: profile.name,
				value: profile.id
			}
		},
		verifyKeywordExists() {
			if (this.company.key) {
				axios.get(`company/key/exist/${this.company.key}/${this.company.id}`)
				.then((response) => {
					if (response.data.key_exist) {
						swal('Ops!', 'Esta keyword já está cadastrada no sistema', 'error');
						this.company.key = '';
					}
				})
			}
		},
	}
}
</script>
<style>
  #preview img{
		max-width: 100%;
		max-height: 150px;
		margin: 0 auto;
		display:block;
		cursor: pointer
	}
	.ml-6 {
		margin-left: 7rem
	}
	.upload {
		width: 100%;
		height: 100%;
		z-index:2;
		position: relative;
		color: transparent
	}
</style>